


























import { defineComponent, ref } from '@vue/composition-api';
import { useAuth } from '@/admin/auth';
import { useNotification } from '@/composition/notification';

export default defineComponent({
  name: 'AccountEmail',
  setup(props, context) {
    const auth = useAuth();
    const notification = useNotification();

    const step = ref(1);
    const email = ref('');
    const code = ref('');
    const isSending = ref(false);

    const send = async () => {
      if (!email.value) {
        notification.error('メールアドレスを入力してください');
        return;
      }

      isSending.value = true;
      try {
        await auth.updateEmail(email.value);
        notification.notify('認証コードを送信しました');
        step.value = 2;
      } catch(error) {
        notification.error(error);
      }
      isSending.value = false;
    }

    const verify = async () => {
      if (!code.value) {
        notification.error('認証コードを入力してください');
        return;
      }

      isSending.value = true;
      try {
        await auth.updateEmailVerify(code.value);
        notification.notify('メールアドレスを変更しました');
        context.root.$router.push('/account');
      } catch(error) {
        notification.error(error);
      }
      isSending.value = false;
    }

    return { step, email, code, isSending, send, verify };
  }
});
